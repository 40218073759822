.radio-label {
  border: 2px solid lightgrey;
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
}

.radio-label.checked {
  border-color: dimgrey;
  background-color: #cdcdcd;
  cursor: default;
}

.radio-label input {
  cursor: inherit;
}

.input-box {
  color: rgb(51, 51, 51);
}

.input-box input {
  margin-left: 5px;
  border: 3px solid #ff5a00;
  border-radius: 10px;
  font: inherit;
  color: black;
  padding: 4px;
}

.input-box input:focus {
  border-radius: 10px;
  outline: 3px solid black;
}

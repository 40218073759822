header {
  gap: 30px;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0px 5px 10px dimgrey;
  padding: 20px;
  height: 50px;
}

header h1 {
  margin: 0px;
}

.lang-select {
  margin-left: auto !important;
}

.lang-option {
  color: black;
  background-color: #dadada;
  width: 1.5rem;
  user-select: none;
  padding: 7px !important;
  border: 3px solid transparent;
  margin-left: 0.25rem;
  font-weight: bold;
}

.lang-option:hover {
  background-color: #c0c0c0;
  border-color: #999;
}

.lang-option.active {
  color: white;
  background-color: dimgrey;
  border-color: #3c3c3c;
}

.allegro-login {
  color: white;
  background-color: #ff5a00;
}

.allegro-login img {
  height: 30px;
}

.allegro-login[disabled] {
  border-color: transparent;
  filter: contrast(50%);
}

.nav-item {
  background-color: #eee;
}

.nav-item.active {
  color: white;
  background-color: dimgrey;
  border-color: rgb(17, 17, 17);
}

.radio-container {
  gap: 10px;
  margin-bottom: 5px;
}

.tab-container {
  gap: 25px;
}

.tab-separator {
  width: 90%;
  margin-bottom: 20px;
}

.msg {
  align-items: flex-start;
  gap: 5px;
  padding: 12px;
  border-radius: 25px;
  font-weight: bold;
  white-space: pre-wrap;
}

.msg svg {
  margin-top: 0.25rem;
}

.status {
  border-radius: 100%;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

.status.msg-info {
  outline: 1px solid black;
}

.msg-info {
  color: black;
  background-color: #ddd;
}

.msg-error {
  color: white;
  background-color: rgb(216, 71, 71);
}

.msg-success {
  color: white;
  background-color: rgb(43, 168, 49);
}

main {
  background-color: white;
  min-width: 50%;
  padding: 10px;
  margin: 40px;
  box-shadow: 3px 5px 10px dimgrey;
  border-radius: 14px;
}

.container {
  display: flex;
  align-items: center;
}

.vertical {
  flex-direction: column;
}

.spaced-out {
  gap: 10px;
  margin: 15px;
}

.btn2 {
  gap: 7px;
  border: solid lightgrey;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
}

.btn2:hover:not([disabled]) {
  border-color: transparent;
}

*[visible="false"] {
  display: none;
}

*[disabled] {
  cursor: not-allowed;
}

.clickable {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

h2 {
  margin-bottom: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 1.25rem;
  background-color: rgb(218, 222, 226);
}

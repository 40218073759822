.copy-elem {
  color: rgb(52, 129, 201);
}

.copy-elem svg {
  margin-left: 5px;
}

.copy-elem.clicked {
  color: rgb(65, 201, 65);
}

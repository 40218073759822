.order-list {
  width: 90%;
  border-collapse: collapse;
}

.order-item {
  align-items: flex-start;
  justify-content: space-between;
  /* border: 1px solid grey; */
}

/* .order-item:not(:last-child) {
  border-bottom: none;
} */

.order-item:nth-of-type(odd) {
  background-color: #ddd;
}

.order-items {
  align-items: stretch;
  margin: unset;
  padding: 5px;
  padding-left: 1.5rem;
}

.order-info {
  align-items: flex-start;
  min-width: 30%;
}

.buyer-info {
  width: 20%;
}

.delivery-details {
  gap: 2px;
  width: 30%;
}

.product-name {
  justify-content: space-between;
}

.order-label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 0.8rem;
  margin: 10px 0px;
}

.toolbox {
  justify-content: space-between;
  width: 90%;
}

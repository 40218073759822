#output {
  margin: 10px;
  padding: 15px;
  align-self: flex-start;
  background-color: lightgrey;
  border-radius: 14px;
  max-width: calc(100% - 50px);
}

#output pre {
  white-space: pre-wrap;
  word-break: break-all;
}
